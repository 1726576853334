import React, {useRef, useMemo, useState, useEffect} from 'react';
import {Canvas, useLoader} from '@react-three/fiber';
import {OrbitControls} from '@react-three/drei';
import {TextureLoader} from 'three/src/loaders/TextureLoader';
import './App.css';
import * as THREE from "three";
import {animated, useSprings} from '@react-spring/three'; // For animation

// Create the custom polygon shape
const getShapeCoords = ({ size, shape }) => {

  const squareSize = size; // Size of the square
  const sliceOffset = squareSize * 0.2; // 20% offset

  switch (shape) {
    case 1:
      // Define the points of the square with the sliced corner
      // shape.moveTo(0, 0); // Bottom left
      // shape.lineTo(squareSize, 0); // Bottom right
      // shape.lineTo(squareSize, squareSize); // Top right
      // shape.lineTo(squareSize/2, squareSize); // Top right
      // shape.lineTo(sliceOffset/2 - (sliceOffset * 0.5), squareSize/2); // New top left (flattened)
      // shape.lineTo(0, squareSize/2); // Back to original top left
      // shape.lineTo(0, 0); // Close the shape
      return [
        [0, 0],
        [squareSize, 0],
        [squareSize, squareSize],
        [squareSize / 2, squareSize],
        [sliceOffset / 2 - (sliceOffset * 0.5), squareSize / 2],
        [0, squareSize / 2],
        [0, 0]
      ];
      break;
    case 2:
      // shape.moveTo(0, 0); // Bottom left
      // shape.lineTo(squareSize, 0); // Bottom right
      // shape.lineTo(squareSize, squareSize); // Top right
      // shape.lineTo(squareSize * 0.7, squareSize); // Top right
      // shape.lineTo(sliceOffset/2 - (sliceOffset * 0.5), squareSize * 0.3); // New top left (flattened)
      // shape.lineTo(0, squareSize/2); // Back to original top left
      // shape.lineTo(0, 0); // Close the shape
      return [
        [0, 0],
        [squareSize, 0],
        [squareSize, squareSize],
        [squareSize * 0.7, squareSize],
        [sliceOffset / 2 - (sliceOffset * 0.5), squareSize * 0.3],
        [0, squareSize / 2],
        [0, 0]
      ];
      break;
    case 3:
      // shape.moveTo(0, 0); // Bottom left
      // shape.lineTo(squareSize, 0); // Bottom right
      // shape.lineTo(squareSize, squareSize); // Top right
      // shape.lineTo(squareSize * 0.9, squareSize); // Top right
      // shape.lineTo(sliceOffset/2 - (sliceOffset * 0.5), squareSize * 0.1); // New top left (flattened)
      // shape.lineTo(0, squareSize/2); // Back to original top left
      // shape.lineTo(0, 0); // Close the shape
      return [
        [0, 0],
        [squareSize, 0],
        [squareSize, squareSize],
        [squareSize * 0.9, squareSize],
        [sliceOffset / 2 - (sliceOffset * 0.5), squareSize * 0.1],
        [0, squareSize / 2],
        [0, 0]
      ];
      break;
    case 4:
    default:
      // shape.moveTo(0, 0); // Bottom left
      // shape.lineTo(squareSize, 0); // Bottom right
      // shape.lineTo(squareSize, squareSize); // Top right
      // shape.lineTo(0, 0); // Close the shape
      return [
        [0, 0],
        [squareSize, 0],
        [squareSize, squareSize],
        [0, 0]
      ];
      break;
  }

  return shape;
};

const CreateCenteredCustomShape = ({ coords, depth }) => {
  // Calculate the center of the shape
  const center = useMemo(() => {
    const xValues = coords.map(point => point[0]);
    const yValues = coords.map(point => point[1]);
    const centerX = (Math.max(...xValues) + Math.min(...xValues)) / 2;
    const centerY = (Math.max(...yValues) + Math.min(...yValues)) / 2;
    return { x: centerX, y: centerY };
  }, [coords]);

  // Create the shape using the provided coordinates, offsetting by the center
  const shape = useMemo(() => {
    const shape = new THREE.Shape();
    shape.moveTo(coords[0][0] - center.x, coords[0][1] - center.y); // Starting point

    for (let i = 1; i < coords.length; i++) {
      shape.lineTo(coords[i][0] - center.x, coords[i][1] - center.y);
    }
    shape.autoClose = true; // Close the shape if desired
    return shape;
  }, [coords, center]);

  // Create the extrude geometry
  const geometry = useMemo(() => {
    const options = { depth, bevelEnabled: false };
    return new THREE.ExtrudeGeometry(shape, options);
  }, [shape, depth]);

  return geometry;
}

const EaseInOutAnimation = t => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
const AnimatedLayer = ({ children, name, position, targetScale, targetPosition, duration, delay, visible, style }) => {
  const meshRef = useRef();

  // Use react-spring to animate the scale and position
  // const { pos } = useSpring({
  //   pos:    targetPosition,
  //   from:   { pos: position },
  //   config: {
  //     duration: duration || 1000,
  //     easing: EaseInOutAnimation
  //   },
  //   delay:  delay || 1000,
  // });

  return (
    // <animated.mesh ref={meshRef} name={name} position={pos} receiveShadow castShadow visible={visible}>
    <animated.mesh ref={meshRef} name={name} receiveShadow castShadow visible={visible} position={position}
                   style={style}>
      {children}
    </animated.mesh>
  );
};

const Grid = ({
                size = 10,
                divisions = 10,
                position = [0, 0, 0],
                color = 'gray',
                castShadow = false,
                receiveShadow = false
              }) => {
  const gridSize = size;
  const divisionSize = size / divisions;

  const vertices = [];

  // Create vertices for the grid, centering them around the origin
  for (let i = -gridSize / 2; i <= gridSize / 2; i += divisionSize) {
    // Vertical lines
    vertices.push(i, 0, -gridSize / 2, i, 0, gridSize / 2);
    // Horizontal lines
    vertices.push(-gridSize / 2, 0, i, gridSize / 2, 0, i);
  }

  const geometry = new THREE.BufferGeometry();
  geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));

  const material = new THREE.LineBasicMaterial({ color });

  return (
    <animated.mesh position={position}>
      <lineSegments geometry={geometry} material={material} rotation={[1.57, 0, 0]} castShadow={castShadow}
                    receiveShadow={receiveShadow}/>
    </animated.mesh>
  );
};

const CustomPolygon = React.forwardRef((props, ref) => {
  // Create geometry from the shape
  const geometry = CreateCenteredCustomShape({ coords: getShapeCoords(props), depth: props.depth });

  return (
    // <animated.mesh name={props.name} receiveShadow={props.receiveShadow} castShadow={props.castShadow}
    <animated.mesh name={props.name} receiveShadow={props.receiveShadow} castShadow={props.castShadow}
                   geometry={geometry} position={props.position} style={props.style} visible={props.visible}>
      {props.children}
    </animated.mesh>
  );
});

function Wall({ scrollPosition, animationPoints }) {

  // Define the brick wall dimensions and thickness
  const wallThickness = 0.75;
  const wallHeight = 4;
  const wallWidth = 4;
  const wallOpacity = 1;

  const brickWallTexture = useLoader(TextureLoader, 'data/textures/brick.jpeg');
  const insulationLayerTexture = useLoader(TextureLoader, 'data/textures/gray-dec-tec-deck-tiles-diycamg60-37-15-64_1000.avif');
  // const netLayerTexture = useLoader(TextureLoader, 'net-wall-texture.jpg');
  const plasterLayerTexture = useLoader(TextureLoader, 'data/textures/abstract-white-gray-concrete-wall-textures-surface.jpeg');
  // const floorTexture = useLoader(TextureLoader, 'road_texture.jpg');

  /*const layerInitialPositions = [
    [0, 0, 0],
    [0, 0, wallThickness * 0.71],
    [0, 0, wallThickness * 0.689],
    [0, 0, wallThickness * 0.67],
    [0, 0, wallThickness * 0.575],
  ];
  const layerTargetPositions = [
    [0, 0, 0],
    [2, 0, wallThickness * 1.44],
    [2, 0, wallThickness * 1.42],
    [2, 0, wallThickness * 1.4],
    [1, 0, wallThickness * 0.9],
  ];*/

  const layerInitialPositions = [
    [0, 0, 0],
    [1, 0, wallThickness * 0.9],
    [2, 0, wallThickness * 1.4],
    [2, 0, wallThickness * 1.42],
    [2, 0, wallThickness * 1.44],
  ];
  const layerTargetPositions = [
    [0, 0, 0],
    [0, 0, wallThickness * 0.575],
    [0, 0, wallThickness * 0.67],
    [0, 0, wallThickness * 0.689],
    [0, 0, wallThickness * 0.71],
  ];


  const springs = useSprings(layerTargetPositions.length, Array.from({ length: layerTargetPositions.length }).map((_, index) => {
    return {
      position: scrollPosition >= animationPoints[index] ? layerTargetPositions[index] : layerInitialPositions[index],
      config:   {
        duration: 1000,
        easing:   EaseInOutAnimation
      },
    };
  }));

  return (
    <>
      {/* Brick wall */}
      <AnimatedLayer name="brick_layer" receiveShadow castShadow position={springs[0].position}>
        <boxGeometry args={[wallWidth, wallHeight, wallThickness]}/>
        <meshStandardMaterial map={brickWallTexture}/>
      </AnimatedLayer>

      {/* Insulation */}
      <AnimatedLayer name="insulation_layer" receiveShadow castShadow position={springs[1].position}>
        <boxGeometry args={[wallWidth, wallHeight, wallThickness * 0.15]}/>
        <meshStandardMaterial map={insulationLayerTexture} transparent opacity={wallOpacity}/>
      </AnimatedLayer>

      {/* Mesh */}
      <Grid size={4} divisions={70} name="mesh_layer" receiveShadow={true} castShadow={true} color={'#0f52ba'}
            position={springs[2].position}/>
      {/*Not using below mesh */}
      {/*<AnimatedLayer name="mesh_layer" receiveShadow castShadow position={springs[3].position} >
        <boxGeometry args={[wallWidth, wallHeight, wallThickness * 0.05]}/>
        <meshStandardMaterial color="lightgray" map={netLayerTexture}/>
      </AnimatedLayer>*/}

      {/* Plaster */}
      <CustomPolygon name="plaster_layer" receiveShadow castShadow position={springs[3].position}
                     shape={1} depth={wallThickness * 0.02} size={wallHeight}>
        <meshStandardMaterial map={plasterLayerTexture} color={'#FAFAFA'}/>
      </CustomPolygon>

      {/* Coating */}
      <CustomPolygon name="coating_layer" receiveShadow castShadow position={springs[4].position}
                     shape={4} depth={wallThickness * 0.008} size={wallHeight}>
        <meshStandardMaterial color={'#FFFFFF'} toneMapped={false}/>
      </CustomPolygon>

      {/* Layer 5 */}
      {/*<CustomPolygon shape={3} depth={wallThickness * 0.02} size={wallHeight} position={[2, -1, wallThickness * 1.46]}>
        <meshStandardMaterial  color={'white'} />
      </CustomPolygon>*/}

      {/* Floor */}
      {/*<mesh position={[0, -2, 0]} rotation={[-Math.PI / 2, 0, 0]}>
        <planeGeometry args={[120, 120]}/>
        <meshStandardMaterial map={floorTexture} />
      </mesh>*/}
    </>
  );
}

function App() {
  const animationPoints = [
    0, 20, 30, 40, 50, 60
  ];
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.scrollY + window.innerHeight; // or window.pageYOffset
    setScrollPosition(position);
  };

  const handleLayerScroll = ({ detail }) => {
    setScrollPosition(animationPoints[parseInt(detail.layer)]);
  };

  const handleLayerReverseScroll = ({ detail }) => {
    setScrollPosition(animationPoints[parseInt(detail.layer) - 1]);
  };

  useEffect(() => {
    // window.addEventListener('scroll', handleScroll);
    window.addEventListener('layer.intersect', handleLayerScroll);
    window.addEventListener('layer.intersect.reverse', handleLayerReverseScroll);
    return () => {
      window.removeEventListener('layer.intersect', handleScroll);
      window.removeEventListener('layer.intersect.reverse', handleScroll);
    };
  }, []);
  return (
    <>
      <Canvas shadows camera={{ position: [-5, 0, 6], fov: 50 }} style={{ background: 'transparent' }}>
        <directionalLight castShadow position={[1, 0, 5]} intensity={1}/>
        <ambientLight color={'#FFFFFF'} intensity={1}/>
        <Wall scrollPosition={scrollPosition} animationPoints={animationPoints}/>
        <OrbitControls
          minDistance={4}
          maxDistance={11}
          enablePan={false}
          minPolarAngle={Math.PI / 4}  // Minimum vertical angle (in radians)
          maxPolarAngle={Math.PI / 2}    // Maximum vertical angle (in radians)
          minAzimuthAngle={-Math.PI / 4}  // Minimum horizontal angle (in radians)
          maxAzimuthAngle={Math.PI / 4}   // Maximum horizontal angle (in radians)
        />
      </Canvas>
    </>
  );
}

export default App;
